import React from 'react';
import { CSSReset, ThemeProvider } from '@chakra-ui/core';

import theme from './theme';
import Layout from './components/Layout';

const App: React.FC = () => (
  <ThemeProvider theme={theme}>
    <CSSReset />
    <Layout />
  </ThemeProvider>
);

export default App;
