import React, { FC } from 'react';
import { Box } from '@chakra-ui/core';

const TransitionGroupBox: FC = ({ children }) => (
  <Box width="100%" position="relative">
    {children}
  </Box>
);

export default TransitionGroupBox;
