import React, { FC } from 'react';
import { Flex } from '@chakra-ui/core';

const Root: FC = ({ children }) => (
  <Flex
    as="ul"
    listStyleType="none"
    justifyContent="center"
    padding="4rem 0"
    textTransform="lowercase"
    fontFamily="heading"
  >
    {children}
  </Flex>
);

export default Root;
