import { History } from 'history';
import ReactGA from 'react-ga';

const stripHash = (hash: string): string => hash.replace('#', '');

const setupAnalytics = (history: History): void => {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_TOKEN ?? '');
    ReactGA.pageview(stripHash(window.location.hash) + window.location.search);

    history.listen((location) => {
      ReactGA.pageview(stripHash(location.hash) + location.search);
    });
  }
};

export default setupAnalytics;
