import { ITheme } from '@chakra-ui/core';
import { DeepPartial } from 'utility-types';

type MyTheme = ITheme & {
  colors: {
    background: {
      content: string;
      page: string;
      sidebar: string;
    };
    valeyellow: string;
  };
}

const overrides: DeepPartial<MyTheme> = {
  colors: {
    background: {
      sidebar: 'rgba(0, 0, 0, .85)',
      page: 'rgb(28, 28, 28)',
      content: 'rgba(0, 0, 0, .75)',
    },
    valeyellow: '#D4FF00',
  },
  fonts: {
    body: 'Montserrat',
    heading: 'Muli',
  },
};

export default overrides;
