import React, { FC } from 'react';
import { Flex, Text } from '@chakra-ui/core';

const Soon: FC = () => (
  <Flex
    alignItems="center"
    justifyContent="center"
  >
    <Text fontSize="2.46rem">
      <span>Soon</span>
      <Text as="span" color="valeyellow">.</Text>
    </Text>
  </Flex>
);

export default Soon;
