import React, { FC } from 'react';
import { Box } from '@chakra-ui/core';

const Root: FC = ({ children }) => (
  <Box color="white" backgroundColor="background.page">
    {children}
  </Box>
);

export default Root;
