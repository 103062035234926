import React, { FC, useMemo } from 'react';
import { Box } from '@chakra-ui/core';
import { NavLink } from 'react-router-dom';

export type NavigationItemProps = {
  to?: string;
  href?: string;
  label: string;
}

const NavigationItem: FC<NavigationItemProps> = ({ label, to, href }) => {
  const isExternal = useMemo(() => href && !to, [href, to]);

  return (
    <Box as="li" marginRight={5}>
      {isExternal ? (
        <a href={href} target="_blank" rel="noopener noreferrer">
          {label}
        </a>
      ) : (
        <NavLink to={to ?? href ?? '#'}>
          {label}
        </NavLink>
      )}
    </Box>
  );
};

export default NavigationItem;
