import React, { FC } from 'react';

import * as Paths from '../../routing/paths';
import NavigationItem from '../NavigationItem';

import Root from './containers/Root';

const Navigation: FC = () => (
  <Root>
    <NavigationItem label="Home" to={Paths.HOME} />
    <NavigationItem label="About me" to={Paths.ABOUT_ME} />
    <NavigationItem label="My work" to={Paths.MY_WORK} />
    <NavigationItem label="CV" to={Paths.CV} />
  </Root>
);

export default Navigation;
