import React, { FC } from 'react';

import Navigation from '../Navigation';

import Root from './containers/Root';

const Header: FC = () => (
  <Root>
    <Navigation />
  </Root>
);

export default Header;
