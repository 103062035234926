import React, { FC, ReactNode } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import Header from '../Header';
import HomePage from '../../pages/HomePage';
import * as Paths from '../../routing/paths';
import './Layout.scss';
import AboutMePage from '../../pages/AboutMePage';
import MyWorkPage from '../../pages/MyWorkPage';
import CVPage from '../../pages/CVPage';
import setupAnalytics from '../../modules/analytics';

import Content from './containers/Content';
import Root from './containers/Root';

const history = createBrowserHistory();

setupAnalytics(history);

const Layout: FC = () => (
  <Root>
    <Router basename={process.env.PUBLIC_URL}>
      <Header />
      <Route render={(({ location }): ReactNode => (
        <Content location={location}>
          <Switch location={location}>
            <Route exact path={Paths.ABOUT_ME}>
              <AboutMePage />
            </Route>
            <Route exact path={Paths.MY_WORK}>
              <MyWorkPage />
            </Route>
            <Route exact path={Paths.CV}>
              <CVPage />
            </Route>
            <Route exact path={Paths.HOME}>
              <HomePage />
            </Route>
            <Route path="*">
              <div>Yikes! Page not found</div>
            </Route>
          </Switch>
        </Content>
      ))}
      />
    </Router>
  </Root>
);

export default Layout;
