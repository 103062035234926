import React, { FC } from 'react';
import * as History from 'history';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Box } from '@chakra-ui/core';

import TransitionGroupBox from './TransitionGroupBox';

type Content = {
  location: History.Location;
}

const Content: FC<Content> = ({ location, children }) => (
  <Box as="main" height="100%" position="relative" top="-4rem">
    <TransitionGroup component={TransitionGroupBox}>
      <CSSTransition
        classNames="fade"
        key={location.pathname}
        timeout={335}
      >
        {children}
      </CSSTransition>
    </TransitionGroup>
  </Box>
);

export default Content;
