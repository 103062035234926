import React, { FC } from 'react';
import { PseudoBox, PseudoBoxProps, Flex } from '@chakra-ui/core';

import background from '../../../assets/background.jpg';
import smallBackground from '../../../assets/smallBackground.svg';

const Root: FC = ({ children }) => (
  <>
    <Flex
      as="nav"
      justifyContent="center"
      position="absolute"
      width="100%"
    >
      {children}
    </Flex>
    <PseudoBox
      background={`url(${background}), url(${smallBackground}) center;`}
      backgroundSize="cover"
      height="20rem"
      _before={{
        content: '""',
        display: 'flex',
        height: '100%',
        background: 'linear-gradient(180deg, rgba(28,28,28,0) 0%, rgba(28,28,28,.5) 40%, rgba(28,28,28,1) 80%);',
      } as PseudoBoxProps}
    />
  </>
);

export default Root;
